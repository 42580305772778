function LoginCtrl($scope, Auth, minPasswordLength,
$window, $location, $routeParams, $cacheFactory, $timeout) {
  this.user = {};
  this.strategy = 'local'; //init

  this.$window = $window; //better way to pass to prototype?

  this.minPasswordLength = minPasswordLength;

  this.message = $routeParams.message || '';

  this.serverMessages = {};

  this.loginComplete = false;

  const _complete = () => {
    $window.requestAnimationFrame(() => {
      this.loginComplete = true;

      // $location.url('/home');
      $timeout(() => {
        if ($routeParams.path) {
          $location.path($routeParams.path).search($routeParams.params || '');
        } else {
          $location.url('/home');
        }
      }, 200);
    });
  };

  // to simplify behavior, all server errors stored in password field
  this.login = (form) => {
    $window.requestAnimationFrame(() => {
      this.submitted = true;
        Auth.login(this.user, this.strategy).then(() => {
          this.error = null;
          // this should probably move to the job model

          // Not sure why it is necessary to call using "this
          // could that be a babel bug?
          // _this2 is undefined, unless I use fat arrow syntax for _complete
          _complete();
        }, (response) => {
          $timeout(() => {
            // Failed due to HTTP error
            if(response) {
              if (response.status === 500) {
                this.serverMessages.error = 'Server issue... we\'re is on it';
              } else if (response.status === 422 ) {
                 this.serverMessages.error = 'Incorrect username/password';
              } else {
                this.serverMessages.error = 'Server issue... we\'re is on it';
              }
            }
            this.submitted = false; 
          }, 200);
        });
    });
  };

  this.guest = () => Auth.login().then(_complete());

  this.loginOauth = provider => this.$window.location.href = '/auth/' + provider;
}

angular.module('sq.user.loginCtrl',
  ['sq.user.auth'])
.controller('LoginCtrl', LoginCtrl);
